import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import triviaSlam from "../../Assets/Projects/triviaSlam.png";
import smalTalks from "../../Assets/Projects/smalTalks.png";
import mathMod from "../../Assets/Projects/mathMod.png";
import fixate from "../../Assets/Projects/fixate.png";
import congressNow from "../../Assets/Projects/congressNow.png";
import toothlessFRC23 from "../../Assets/Projects/toothlessFRC23.png";
import unusualAnswers from "../../Assets/Projects/unusualAnswers.png";
import photos from "../../Assets/Projects/photos.jpg";
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fixate}
              isBlog={false}
              title="Fixate"
              description="An app to reclaim your time. Distraction blocking. Realtime focus with friends. Track your time. MacOS, Windows, iOS."
              ghLink="https://github.com/shoryamalani/fixate"
              demoLink="https://fixateapp.dev"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={toothlessFRC23}
              isBlog={false}
              title="422 FRC Code"
              description="Robot Code for Toothless 422's 2023 Season Robot. Swerve Drive, Vision, Multi-level control systems and more."
              ghLink="https://github.com/team422/FRC-23"
              demoLink="https://www.youtube.com/watch?v=4lDgbdVWs70"
            />
          </Col>

          

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={triviaSlam}
              isBlog={false}
              title="Trivia Slam"
              description="An app for QuizBowl players to practice and improve their skills. The app is built to improve the data analysis of players and questions. "
              ghLink="https://github.com/shoryamalani/quizbowl_website"
              demoLink="https://apps.apple.com/in/app/trivia-slam/id1636093093"
            />
          </Col>
      <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mathMod}
              isBlog={false}
              title="Math Competitions"
              description="Models for complex topics like simulating the spread of dandelions"
              ghLink="https://github.com/soumyajit4419/Editor.io"
              // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={unusualAnswers}
              isBlog={false}
              title="Unusual Answers"
              description="A Youtube Channel for scientific concepts and events."
              // ghLink=""
              demoLink="https://www.youtube.com/@unusualanswers4537"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={smalTalks}
              isBlog={false}
              title="Substack"
              description="Heres a blog of some of my opinions on things and some code."
              demoLink="https://shoryamalani.substack.com"
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              //      <--------Please include a demo link here 
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={congressNow}
              isBlog={false}
              title="Congress Now"
              description="A website providing realtime data about bills in congress. Website currently inactive (coming back soon)"
              ghLink="https://github.com/shoryamalani/congress_now"
              demoLink="https://congressnow.shoryamalani.com" //     <--------Please include a demo link here 
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={photos}
              isBlog={false}
              title="Photos"
              description="Photos by Shorya Malani"
              // ghLink="https://github.com/shoryamalani/congress_now"
              demoLink="https://media.shoryamalani.com" //     <--------Please include a demo link here 
            />
          </Col>
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={smalTalks}
              isBlog={false}
              title="Work for Clearpoint Management"
              description="Scraped web data "
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
